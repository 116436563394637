import axios from "axios";
import { v4 as uuidv4 } from "uuid";

// const baseAuthURL = import.meta.env.VITE_API_ENDPOINT;

const baseAuthURL = process.env.REACT_APP_API_URL;

// console.log("baseAuthURL", baseAuthURL);

export const apiPost = (data) => {
  let uuid = uuidv4();
  let headers = {
    "Content-Type": "application/json",
    "x-request-id": uuid,
    timestamp: new Date(),
  };
  return axios
    .post(baseAuthURL, data, { headers: headers })
    .then((response) => {
      // handle success
      let data = {
        ...response,
        data: {
          ...response.data,
        },
      };
      return data;
    })
    .catch((error) => {
      // handle error
      console.log("Auth Error:", error);
      return error;
    });
};
